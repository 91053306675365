<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col-4">
          <div class="profileSection">
            <img
              :src="require('../assets/profile.jpg')"
              class="profile shadow"
            />
            <h1 class="mt-3 text-center w-100"><strong>何承軒</strong></h1>
            <div class="text-center w-100">
              <span class="el_style_text"
                >國立彰化師範大學<br />附屬高級工業職業學校</span
              >
              <hr />
            </div>
          </div>
          <h5 class="mb-0 mb-2">專長與興趣</h5>
          <div class="row">
            <div class="col-4 fcc subjects" v-for="data in subjects">
              <img :src="data.icon" style="width: 85%" />
              <span class="p" style="font-size: 16px">{{ data.name }}</span>
            </div>
          </div>

          <h5 class="mt-3">專業證照</h5>
          <div class="w-100">
            <div
              v-for="data in skills"
              class="skills m-0 fac"
              style="height: 45px"
            >
              <span class="badge rounded-pill badge-primary">
                {{ data.type }}
              </span>
              <div
                style="color: #606266; line-height: 1.5; font-size: 18px"
                class="m-0 p-0"
              >
                {{ data.name }}
              </div>
            </div>
          </div>

          <h5 class="mt-3">社團與服務參與</h5>
          <div class="row mb-2">
            <div class="col-3">
              <span class="el_style_strong_text">年度</span>
            </div>
            <div class="col-9">
              <span class="el_style_strong_text"> 活動描述 </span>
            </div>
          </div>
          <div v-for="(data, index) in societies" class="row" :key="index">
            <div class="col-3">
              <span class="el_style_text">
                {{ data.timestamp }}
              </span>
            </div>
            <div class="col-9">
              <span v-html="data.description" class="el_style_text"> </span>
              <hr class="mt-1 mb-1" v-if="index < bugs.length" />
            </div>
          </div>
        </div>

        <div class="col-8 mt-5" style="padding-left: 30px">
          <h3>重大比賽參與</h3>
          <hr class="mb-3" />
          <el-timeline class="m-0 p-0 ml-1">
            <el-timeline-item
              v-for="(data, index) in events"
              :key="index"
              :timestamp="data.timestamp"
              :size="data.size"
              :type="data.type"
            >
              <span class="badge rounded-pill" v-bind:class="[data.badge]">
                {{ data.name.split(" ").pop() }}
              </span>
              <span style="margin-left: 5px; font-size: 16px; color: #333">
                {{ data.name.split(" ").slice(0, -1).join(" ") }}
              </span>
            </el-timeline-item>
          </el-timeline>
          <div class="row mb-4">
            <div class="col-3">
              <div class="dot-body fcc">
                <div class="dot bg-danger"></div>
                <span class="text-danger" style="font-size: 16px"> 全國 </span>
              </div>
            </div>
            <div class="col-3">
              <div class="dot-body fcc">
                <div class="dot bg-primary"></div>
                <span class="text-primary" style="font-size: 16px">
                  單區範圍
                </span>
              </div>
            </div>
            <div class="col-3">
              <div class="dot-body fcc">
                <div class="dot bg-success"></div>
                <span
                  class="text-success"
                  style="color: #dea700; font-size: 16px"
                >
                  校內
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h4>專業軟體操作能力</h4>
              <hr />
            </div>
            <div class="fcc col-20 mb-4" v-for="data in software">
              <img
                style="height: 45px; max-width: 80px; width: auto"
                :src="data.icon"
              />
              <span class="el_style_text mt-2 text-center w-100">
                {{ data.name }}
              </span>
            </div>

            <div class="col-12">
              <h4>語言能力</h4>
              <hr />
            </div>
            <div class="fcc col-20 mb-4" v-for="data in languages">
              <el-badge
                type="primary"
                :value="data.power"
                class="item"
                v-if="data.power == '精熟'"
              >
                <div class="ios-app">
                  <img
                    style="height: 45px; max-width: 80px; width: auto"
                    :src="data.icon"
                  />
                </div>
              </el-badge>
              <div class="ios-app" v-if="data.power != '精熟'">
                <img
                  style="height: 45px; max-width: 80px; width: auto"
                  :src="data.icon"
                />
              </div>
              <span class="el_style_text mt-2 text-center w-100">
                {{ data.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "resume",
  data() {
    return {
      events: [
        {
          timestamp: "2020-04-25",
          name: "勞動部第50屆全國技能競賽中區分區賽 應用電子(視聽電子) 第六名",
          size: "large",
          pro: true,
          badge: "badge-primary",
        },
        {
          timestamp: "2020-04-25",
          name: "勞動部第51屆全國技能競賽中區分區賽 應用電子(視聽電子) 第四名",
          size: "large",
          pro: true,
          badge: "badge-primary",
        },
        {
          timestamp: "2020-11-27",
          name: "教育部全國高中工業類學生技藝競賽 數位電子 優勝第11名",
          size: "large",
          pro: true,
          badge: "badge-danger",
        },
        {
          timestamp: "2021-05-24",
          name: "第1100315梯次小論文寫作比賽 優等",
          size: "large",
          pro: true,
          badge: "badge-danger",
        },
        {
          timestamp: "2020-12-15",
          name: "英文學科競試 高職三年級組 第2名",
          size: "large",
          pro: true,
          badge: "badge-success",
        },
        {
          timestamp: "2019-11-20",
          name: "英文學科競試 高職二年級組 第8名",
          size: "large",
          pro: true,
          badge: "badge-success",
        },
      ],
      skills: [
        {
          type: "丙級",
          name: "工業電子",
          timestamp: "2018-11-25",
        },
        {
          type: "乙級",
          name: "數位電子",
          timestamp: "2021-02-26",
        },
        {
          type: "960分",
          name: "多益",
          timestamp: "2020-05-09",
        },
        {
          type: "中級",
          name: "全民英檢",
          timestamp: "2018-08-18",
        },
      ],
      societies: [
        {
          timestamp: "107",
          description: "於校刊「文青 45th」<br />投稿英文文章獲得刊登發表",
        },
        {
          timestamp: "108",
          description: "於校刊「斜槓 46th」<br />投稿英文文章獲得刊登發表",
        },
        {
          timestamp: "108",
          description: "全程參加 NCUExSIVS 2019 英語營",
        },
        {
          timestamp: "109",
          description: "參加攝影社<br />五校聯合迎新活動",
        },
        {
          timestamp: "109",
          description: "參與國際獅子會<br />捐血救人運動共5次",
        },
      ],
      bugs: [
        {
          name: "學校師生管理系設計缺陷，重要個資外流。",
          time: "2019",
        },
        {
          name: "台灣某公司網站資料庫注入漏洞",
          time: "2019",
        },
        {
          name: "麥當勞報報用戶端與服務端多重權限與邏輯漏洞導致商業機密外流",
          time: "2020",
        },
        {
          name: "電機電子群專題報名系統檔案讀取權限缺失，導致各組競賽資料外流",
          time: "2021",
        },
      ],
      software: [
        {
          name: "Visual Studio",
          icon: require("../assets/software-icons/vs.svg"),
        },
        {
          name: "Visual Studio Code",
          icon: require("../assets/software-icons/vscode.svg"),
        },
        {
          name: "Neovim IDE",
          icon: require("../assets/software-icons/neovim.svg"),
        },
        {
          name: "Git",
          icon: require("../assets/software-icons/git.svg"),
        },
        {
          name: "Quartus Prime",
          icon: require("../assets/software-icons/Quartus-Prime-.png"),
        },
        {
          name: "Keil µVision",
          icon: require("../assets/software-icons/arm.svg"),
        },
        {
          name: "Arduino",
          icon: require("../assets/software-icons/arduino.svg"),
        },
        {
          name: "Arch Linux",
          icon: require("../assets/software-icons/arch.svg"),
        },
        {
          name: "Apache",
          icon: require("../assets/software-icons/apache.svg"),
        },
        {
          name: "Nextcloud",
          icon: require("../assets/software-icons/nc.svg"),
        },
        {
          name: "Postfix",
          icon: require("../assets/software-icons/postfix.svg"),
        },
        {
          name: "Fish Shell",
          icon: require("../assets/software-icons/fish.svg"),
        },
        {
          name: "Photoshop",
          icon: require("../assets/software-icons/ps.svg"),
        },
        {
          name: "Illustrator",
          icon: require("../assets/software-icons/ai.svg"),
        },
        {
          name: "Premiere Pro",
          icon: require("../assets/software-icons/pr.svg"),
        },
      ],
      subjects: [
        {
          name: "英文",
          icon: require("../assets/icons/abc.svg"),
        },
        {
          name: "電子學",
          icon: require("../assets/icons/electron.svg"),
        },
        {
          name: "數位邏輯",
          icon: require("../assets/icons/chip.svg"),
        },
      ],
      languages: [
        {
          name: "Python",
          icon: require("../assets/language-icons/py.svg"),
          power: "精熟",
        },
        {
          name: "C",
          icon: require("../assets/language-icons/c.svg"),
          power: "精熟",
        },
        {
          name: "C++",
          icon: require("../assets/language-icons/cpp.svg"),
          power: "",
        },
        {
          name: "Rust",
          icon: require("../assets/language-icons/rust.svg"),
          power: "",
        },
        {
          name: "Visual Basic",
          icon: require("../assets/software-icons/vs.svg"),
          power: "精熟",
        },
        {
          name: "VHDL",
          icon: require("../assets/language-icons/vhdl.svg"),
          power: "精熟",
        },
        {
          name: "Verilog",
          icon: require("../assets/language-icons/verilog.svg"),
          power: "",
        },
        {
          name: "Tcl",
          icon: require("../assets/language-icons/tcl.svg"),
          power: "",
        },
        {
          name: "JavaScript",
          icon: require("../assets/language-icons/js.svg"),
          power: "",
        },
        {
          name: "Bash",
          icon: require("../assets/language-icons/bash.svg"),
          power: "精熟",
        },
      ],
    };
  },
  methods: {
    order(data, desc = false) {
      data.sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp);
      });
      return desc ? data.reverse() : data;
    },
    covert(data) {
      return data
        .map((e) => {
          e.name.split(" ").pop() == "第一名" ||
          e.name.split(" ").pop() == "金牌"
            ? (e.bg = "bg-warning")
            : "";
          e.name.includes("技能競賽") &&
          e.name.split(" ").pop() != "第一名" &&
          e.name.split(" ").pop() == "第二名"
            ? (e.bg = "bg-primary")
            : "";
          e.bg == undefined &&
          e.name.split(" ").pop() != "第一名" &&
          !e.name.includes("書法")
            ? (e.bg = "bg-success")
            : "";
          e.bg == undefined ? (e.bg = "bg-secondary") : "";
          e.fontColor = e.bg == "bg-warning" ? "text-dark" : "text-white";
          return e;
        })
        .filter((e) => e.pro);
    },
  },
  created() {
    this.events = this.covert(this.order(this.events));
    // console.log(this.events.map(e => e.name).join("\n"));
  },
};
</script>

<style lang="scss" scoped>
.profile {
  border-radius: 50%;
  width: 100%;
  border: 2px #ddd solid;
  filter: opacity(1);
  -webkit-print-color-adjust: exact;
  -webkit-filter: opacity(1);
}

.profileSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.skills {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1px rgba(0, 0, 0, 0.3) solid;
  padding: 5px 0;
}

.skills:last-child {
  border: none;
}

.el_style_strong_text {
  color: #909399;
  font-weight: 800;
  font-size: 16px;
}
.el_style_text {
  color: #606266;
  font-size: 16px;
}
.societies_table {
  padding: 5px 0;
  margin-top: -2.5px;
}
.societies_table > .col-8 {
  border-bottom: 0.1px rgba(0, 0, 0, 0.1) solid;
  box-sizing: border-box;
}
.societies_table:last-child > .col-8 {
  border: none;
}
.el-timeline-item__content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.dot-body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}
.subjects {
  border-right: 0.1px rgba(0, 0, 0, 0.1) solid;
}
.subjects:last-child {
  border: none;
}
.badge {
  font-size: 16px !important;
}
.ios-app {
  width: 60px;
  height: 60px;
  border: 0.1px rgba(0, 0, 0, 0.1) solid;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-badge__content.is-fixed {
  top: 2.5px !important;
  right: 15px !important;
  padding: 10px 7.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
