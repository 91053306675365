var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-12"},[_c('img',{attrs:{"src":require('../assets/校內成績/score.png')}})])])]),_c('footer',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row",style:({
          'flex-direction':
            (_vm.$router.options.routes
              .map(function (e) { return e.path; })
              .indexOf(_vm.$router.currentRoute.path) -
              _vm.$store.state.indexStep) %
              2 ==
            1
              ? ' row-reverse'
              : 'row',
        })},[_c('div',{staticClass:"col-11",class:[
            (_vm.$router.options.routes
              .map(function (e) { return e.path; })
              .indexOf(_vm.$router.currentRoute.path) -
              _vm.$store.state.indexStep) %
              2 ==
            1
              ? ' fsc'
              : 'fec' ]},[_c('div',[_vm._m(1),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.$store.state.school))])])]),_c('div',{staticClass:"col-1"},[_c('h6',{staticClass:"pageNumber"},[_vm._v(" "+_vm._s(_vm.$router.options.routes .map(function (e) { return e.path; }) .indexOf(this.$router.currentRoute.path) - _vm.$store.state.indexStep)+" ")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"col-sm-12 mb-3"},[_c('h1',{staticClass:"mb-4 text-n"},[_c('strong',[_vm._v("在校成績單")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"m-0"},[_vm._v("在校成績單")])])}]

export { render, staticRenderFns }