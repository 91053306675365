<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row fsc">
        <div class="col-2">
          <img :src="require('../assets/icons/albums.svg')" class="w-100" />
        </div>
        <div class="col-10">
          <h3 class="section_main_title">
            <strong class="text-nord3">GenMIF</strong>
          </h3>
          <p class="">
            開發TFT
            LCD模組的控制器時，我透過白皮書發現將圖片儲存於RAM中效率更好。Quartus軟體中設定記憶體內容需要用不易編寫的MIF檔案，因此我開發了GenMIF軟體，大幅提升轉換圖片的效率。
          </p>
          <hr />
          <p class="mb-2">
            <strong class="mr-3">&middot;</strong>
            接收標準 POSIX 指令，可以配合其他程式
          </p>
          <p class="mb-2">
            <strong class="mr-3">&middot;</strong>
            輸入 <el-tag>JPG, PNG, BMP, GIF, SVG</el-tag> 等圖片格式皆可轉檔
          </p>
          <p class="mb-2">
            <strong class="mr-3">&middot;</strong>
            自動縮放裁剪，可以選擇填滿或全螢幕
          </p>
          <p class="mb-2">
            <strong class="mr-3">&middot;</strong>
            可視空間需求輸出1-bit雙色、8-bit節省空間或24-bit全彩圖片
          </p>
        </div>
      </div>
      <div class="row fcc mt-4">
        <div class="col-6 mt-5">
          <img
            :src="require('../assets/itc-code/PM5544.png')"
            class="w-100 macos-drop-shadow"
          />
          <p class="mt-5 fcc">GenMIF 立即產生可供編譯的程式碼</p>
        </div>
        <div class="col-1 fcc">
          <i
            style="font-size: 24px; font-weight: 900"
            class="el-icon-arrow-right"
          ></i>
        </div>
        <div class="col-5">
          <img
            :src="require('../assets/itc-code/genmif.png')"
            class="w-100 shadow"
          />
        </div>
      </div>

      <div class="row fsc mt-5">
        <div class="col-2">
          <img
            :src="require('../assets/software-icons/vscode.svg')"
            class="w-100"
          />
        </div>
        <div class="col-10">
          <h3 class="section_main_title">
            <strong class="text-nord3">VS Code Tcl 腳本</strong>
          </h3>
          <p class="">
            不僅賽中分秒必爭，練習也一樣。我們上網自學 VS Code 的 API
            以及如何利用 Tcl 自動化 Quartus 編譯，寫出了
            <strong class="text-nord3"> 能夠一鍵完成例行工作的腳本 </strong>
            。
          </p>
          <el-steps class="mt-3" :active="3" simple>
            <el-step title="撰寫程式" icon="el-icon-edit"></el-step>
            <el-step title="編譯" icon="el-icon-s-tools"></el-step>
            <el-step title="FPGA燒錄" icon="el-icon-cpu"></el-step>
          </el-steps>
        </div>
      </div>
    </div>
    <footer>
      <div class="container-fluid">
        <div
          class="row"
          v-bind:style="{
            'flex-direction':
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' row-reverse'
                : 'row',
          }"
        >
          <div
            class="col-11"
            v-bind:class="[
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' fsc'
                : 'fec',
            ]"
          >
            <div>
              <div>
                <!--                <h5 class="m-0 pr-2 mr-2" style="border-right: .1px rgba(0, 0, 0, .3) solid">自傳</h5>-->
                <h5 class="m-0">作品集 | 工科賽開發成果</h5>
              </div>
              <p class="m-0">{{ $store.state.school }}</p>
            </div>
          </div>
          <div class="col-1">
            <h6 class="pageNumber">
              {{
                $router.options.routes
                  .map((e) => e.path)
                  .indexOf(this.$router.currentRoute.path) -
                $store.state.indexStep
              }}
            </h6>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "portfolio_itc_2",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
