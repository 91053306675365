<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h3 class="section_main_title">
            <strong class="text-nord3">FPGA Tools</strong>
          </h3>
          <p>強大的GUI操作介面，滑鼠點一點、欄位填一填，完成。</p>
        </div>
      </div>
      <!--   8x8點矩陣產生器   -->
      <div class="row mt-4 fcc">
        <div class="col-12">
          <img
            class="w-100 macos-drop-shadow"
            :src="require('../assets/FPGATools/tts_使用介面.png')"
          />
          <h4 class="text-center mt-3">Big5 to VHDL</h4>
          <p class="fcc text-center">
            比賽中有文字轉語音模組，透過I2C接收大五碼中文編碼並念出。我們寫出
            <br />
            <strong class="text-nord3">
              能夠大量轉換中文句子成 VHDL
              大五碼陣列的程式，在比賽中成功爭取時間優勢
            </strong>
            。
          </p>
        </div>
        <div class="col-12">
          <img
            class="w-100 macos-drop-shadow"
            style="width: 90%"
            :src="require('../assets/FPGATools/點矩陣顯示器_draw.png')"
          />
          <h4 class="text-center mt-3">DotMatrixTool</h4>
          <p class="fcc text-center">
            比賽中出現許多複雜的點矩陣LED排列，導致編排上耗時又困難，於是我們開發出
            <br />
            <strong class="text-nord3">
              能夠使用鼠標拖拉的方式繪製圖形並輸出程式片段，這個工具也在比賽中成功爭取時間優勢
            </strong>
            。
          </p>
        </div>
      </div>

      <!--   本地儲存   -->
      <div class="row mt-4 fcc">
        <div class="col-6">
          <img
            :src="require('../assets/FPGATools/local_sql.png')"
            class="w-100 shadow"
          />
        </div>
        <div class="col-6">
          <h4>自動儲存，不怕網頁刷新一切重來</h4>
          <p class="m-0">
            利用Web
            Storage儲存技術，偵測到編輯活動時，系統會自動將變更儲存下來，確保萬無一失。
          </p>
        </div>
      </div>
    </div>
    <footer>
      <div class="container-fluid">
        <div
          class="row"
          v-bind:style="{
            'flex-direction':
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' row-reverse'
                : 'row',
          }"
        >
          <div
            class="col-11"
            v-bind:class="[
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' fsc'
                : 'fec',
            ]"
          >
            <div>
              <div>
                <!--                <h5 class="m-0 pr-2 mr-2" style="border-right: .1px rgba(0, 0, 0, .3) solid">自傳</h5>-->
                <h5 class="m-0">作品集 | 工科賽開發成果</h5>
              </div>
              <p class="m-0">{{ $store.state.school }}</p>
            </div>
          </div>
          <div class="col-1">
            <h6 class="pageNumber">
              {{
                $router.options.routes
                  .map((e) => e.path)
                  .indexOf(this.$router.currentRoute.path) -
                $store.state.indexStep
              }}
            </h6>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
const fs = require("fs");
const QRCode = require("qrcode");
export default {
  name: "portfolio_itc_3",
  data() {
    return {
      urls: [
        {
          name: "libitc",
          url: "https://github.com/WeedForJustice/libitc",
        },
        {
          name: "Vpp",
          url: "https://github.com/WeedForJustice/libitc/blob/develop/tools/pp.py",
        },
        {
          name: "GenMIF",
          url: "https://github.com/WeedForJustice/libitc/blob/develop/tools/genmif.py",
        },
        {
          name: "Tcl 腳本",
          url: "https://github.com/WeedForJustice/libitc/blob/develop/tools/compile.tcl",
        },
        {
          name: "FPGA Tools",
          url: "https://github.com/ChengHung-Wang/FPGA-Tools",
        },
      ],
    };
  },
  methods: {
    qrcode(url) {
      let returnData = null;
      QRCode.toDataURL(
        url,
        {
          margin: 1,
          width: 200,
          height: 200,
        },
        function (err, qrcodeUrl) {
          returnData = qrcodeUrl;
        }
      );
      return returnData;
    },
  },
};
</script>

<style lang="scss" scoped></style>
