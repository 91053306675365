import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import cover from "../views/cover.vue";
import resume from "../views/resume.vue";
import toc from "../views/toc.vue";
import bio_1 from "../views/bio_1.vue";
import bio_2 from "../views/bio_2.vue";
import sop from "../views/sop.vue";
import score from "../views/score.vue";

import cert_1 from "../views/cert_1.vue";
import cert_2 from "../views/cert_2.vue";

import portfolio_essay from "../views/portfolio_essay.vue";
import portfolio_itc_1 from "../views/portfolio_itc_1.vue";
import portfolio_itc_2 from "../views/portfolio_itc_2.vue";
import portfolio_itc_3 from "../views/portfolio_itc_3.vue";
import portfolio_itc_4 from "../views/portfolio_itc_4.vue";
import portfolio_newhdl from "../views/portfolio_newhdl.vue";

Vue.use(VueRouter);

// Route Name Rule: {{ sectionNumber }}/{{ sectionDescription }}/(option if page > 2){{ pageNumber }}
const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "cover",
    component: cover,
  },
  {
    path: "/toc",
    name: "toc",
    component: toc,
  },
  {
    path: "/resume",
    name: "resume",
    component: resume,
  },

  {
    path: "/bio/1",
    name: "bio",
    component: bio_1,
  },
  {
    path: "/bio/2",
    name: "bio",
    component: bio_2,
  },
  {
    path: "/sop",
    name: "sop",
    component: sop,
  },
  {
    path: "/portfolio/essay",
    name: "portfolio_essay",
    component: portfolio_essay,
  },
  {
    path: "/portfolio/itc/1",
    name: "portfolio_itc",
    component: portfolio_itc_1,
  },
  {
    path: "/portfolio/itc/2",
    name: "portfolio_itc",
    component: portfolio_itc_2,
  },
  {
    path: "/portfolio/itc/3",
    name: "portfolio_itc",
    component: portfolio_itc_3,
  },
  {
    path: "/portfolio/itc_4",
    name: "portfolio_itc",
    component: portfolio_itc_4,
  },
  {
    path: "/portfolio/newhdl",
    name: "portfolio_newhdl",
    component: portfolio_newhdl,
  },
  {
    path: "/cert/1",
    name: "cert",
    component: cert_1,
  },
  {
    path: "/cert/2",
    name: "cert",
    component: cert_2,
  },
  {
    path: "/score",
    name: "score",
    component: score,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
