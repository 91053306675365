var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"row mt-4 fcc"},[_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"w-100 macos-drop-shadow",attrs:{"src":require('../assets/FPGATools/tts_使用介面.png')}}),_c('h4',{staticClass:"text-center mt-3"},[_vm._v("Big5 to VHDL")]),_vm._m(1)]),_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"w-100 macos-drop-shadow",staticStyle:{"width":"90%"},attrs:{"src":require('../assets/FPGATools/點矩陣顯示器_draw.png')}}),_c('h4',{staticClass:"text-center mt-3"},[_vm._v("DotMatrixTool")]),_vm._m(2)])]),_c('div',{staticClass:"row mt-4 fcc"},[_c('div',{staticClass:"col-6"},[_c('img',{staticClass:"w-100 shadow",attrs:{"src":require('../assets/FPGATools/local_sql.png')}})]),_vm._m(3)])]),_c('footer',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row",style:({
          'flex-direction':
            (_vm.$router.options.routes
              .map(function (e) { return e.path; })
              .indexOf(_vm.$router.currentRoute.path) -
              _vm.$store.state.indexStep) %
              2 ==
            1
              ? ' row-reverse'
              : 'row',
        })},[_c('div',{staticClass:"col-11",class:[
            (_vm.$router.options.routes
              .map(function (e) { return e.path; })
              .indexOf(_vm.$router.currentRoute.path) -
              _vm.$store.state.indexStep) %
              2 ==
            1
              ? ' fsc'
              : 'fec' ]},[_c('div',[_vm._m(4),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.$store.state.school))])])]),_c('div',{staticClass:"col-1"},[_c('h6',{staticClass:"pageNumber"},[_vm._v(" "+_vm._s(_vm.$router.options.routes .map(function (e) { return e.path; }) .indexOf(this.$router.currentRoute.path) - _vm.$store.state.indexStep)+" ")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"section_main_title"},[_c('strong',{staticClass:"text-nord3"},[_vm._v("FPGA Tools")])]),_c('p',[_vm._v("強大的GUI操作介面，滑鼠點一點、欄位填一填，完成。")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"fcc text-center"},[_vm._v(" 比賽中有文字轉語音模組，透過I2C接收大五碼中文編碼並念出。我們寫出 "),_c('br'),_c('strong',{staticClass:"text-nord3"},[_vm._v(" 能夠大量轉換中文句子成 VHDL 大五碼陣列的程式，在比賽中成功爭取時間優勢 ")]),_vm._v(" 。 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"fcc text-center"},[_vm._v(" 比賽中出現許多複雜的點矩陣LED排列，導致編排上耗時又困難，於是我們開發出 "),_c('br'),_c('strong',{staticClass:"text-nord3"},[_vm._v(" 能夠使用鼠標拖拉的方式繪製圖形並輸出程式片段，這個工具也在比賽中成功爭取時間優勢 ")]),_vm._v(" 。 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-6"},[_c('h4',[_vm._v("自動儲存，不怕網頁刷新一切重來")]),_c('p',{staticClass:"m-0"},[_vm._v(" 利用Web Storage儲存技術，偵測到編輯活動時，系統會自動將變更儲存下來，確保萬無一失。 ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"m-0"},[_vm._v("作品集 | 工科賽開發成果")])])}]

export { render, staticRenderFns }