<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="section_title mb-2">
            <div class="section_subtitle">
              <strong>新硬體描述語言計劃</strong>
            </div>
            <h3 class="section_main_title">
              突破拖泥帶水語法，降低門檻培育人才
            </h3>
          </div>
          <p class="description">
            現今無論是 VHDL 或是 Verilog ，都是進行 IC
            設計時不可或缺的工具。但兩者各有設計缺失，導致學習不易。為了現存語言解決難以學習的困境，
            我下定決心於大學就讀期間
            <strong>
              利用我已知的多種程式語言為靈感，重新定義硬體描述語言。
            </strong>
          </p>
        </div>
      </div>
      <div class="row mb-4 fcc">
        <div class="row mb-4 fsc">
          <div class="col-8">
            <div class="row fcc" style="align-items: flex-start">
              <div class="col-1">1</div>
              <div class="col-11">
                <p class="m-0">簡化語法與資料結構，降低學習難度發掘更多人才</p>
                <hr />
              </div>
            </div>

            <div class="row fcc" style="align-items: flex-start">
              <div class="col-1">2</div>
              <div class="col-11">
                <p class="m-0">以自由授權公開原始碼，加速社群研發速度</p>
                <hr />
              </div>
            </div>

            <div class="row fcc" style="align-items: flex-start">
              <div class="col-1">3</div>
              <div class="col-11">
                <p class="m-0">增強硬體相容性，使製作測試不再受手邊硬體測試</p>
                <hr />
              </div>
            </div>

            <div class="row fcc" style="align-items: flex-start">
              <div class="col-1">4</div>
              <div class="col-11">
                <p class="m-0">一條龍化部署流程，透過腳本靈活完成工作</p>
              </div>
            </div>
          </div>
          <div class="col-4 fcc">
            <img
              class="w-80"
              :src="require('../assets/language-icons/newhdl.svg')"
            />
            <span class="badge badge-pill bg-success text-white">New VHDL</span>
          </div>
        </div>

        <div class="col-5">
          <img
            class="w-100 shadow"
            :src="require('../assets/itc-code/newhdl-before.png')"
          />
          <p class="mt-3 fcc">
            <i class="el-icon-arrow-up mr-2"></i>
            記數除法器 - Before
          </p>
        </div>
        <div class="col-2 fcc">
          <span class="badge-danger badge" style="font-size: 24px !important">
            V.S
          </span>
        </div>
        <div class="col-5">
          <img
            :src="require('../assets/itc-code/newhdl-after.png')"
            class="w-100 shadow"
          />
          <p class="mt-3 fcc">
            <i class="el-icon-arrow-up mr-2"></i>
            記數除法器 - After
          </p>
        </div>
      </div>
      <div class="col-12 mt-4 fcc">
        <img
          class="w-100 macos-drop-shadow"
          :src="require('../assets/itc-code/Blablabla.png')"
        />
        <p class="mt-4 fcc">
          <i class="el-icon-arrow-up mr-2"></i>
          二進位轉 BCD - Before / After
        </p>
        <span
          class="badge-danger badge"
          style="
            font-size: 24px !important;
            margin-left: -350px;
            margin-top: -200px;
            z-index: 0;
          "
        >
          V.S
        </span>
      </div>
    </div>
    <footer>
      <div class="container-fluid">
        <div
          class="row"
          v-bind:style="{
            'flex-direction':
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' row-reverse'
                : 'row',
          }"
        >
          <div
            class="col-11"
            v-bind:class="[
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' fsc'
                : 'fec',
            ]"
          >
            <div>
              <div>
                <!--                <h5 class="m-0 pr-2 mr-2" style="border-right: .1px rgba(0, 0, 0, .3) solid">自傳</h5>-->
                <h5 class="m-0">作品集 | 新硬體描述語言計劃</h5>
              </div>
              <p class="m-0">{{ $store.state.school }}</p>
            </div>
          </div>
          <div class="col-1">
            <h6 class="pageNumber">
              {{
                $router.options.routes
                  .map((e) => e.path)
                  .indexOf(this.$router.currentRoute.path) -
                $store.state.indexStep
              }}
            </h6>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "portfolio_newhdl",
};
</script>

<style lang="scss" scoped></style>
