<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <!--    libitc    -->
        <div class="col-12">
          <div class="section_title mb-2">
            <div class="section_subtitle">
              <strong>工科賽開發成果</strong>
            </div>
            <h3 class="section_main_title">
              <strong class="text-nord3">libitc</strong>
            </h3>
          </div>
          <p class="mb-1">
            比賽的對手往往都是志趣相投，值得學習的對象。
            <strong class="text-nord3">
              我化對手為盟友，與新北高工的王同學一起開發這個專案，透過不同領域上的技術交換，打造出一個更完整、強大的工具。
            </strong>
          </p>
          <p class="mb-0">
            於兩個月的時間內，我們用 VHDL 製作工科賽數位電子所有硬體的驅動程式，
            <strong class="text-nord2">
              在234次、超過95萬行的更動後，一共33萬行程式碼
            </strong>
            。為了程式碼的永續發展，我們將開發完成的代碼
            <strong class="text-nord2"> 以硬體分類，模組化整個函式庫 </strong>
          </p>
        </div>

        <div class="col-12">
          <div class="row mt-2 mb-2">
            <div class="col-4 mb-2 profile_frame" v-for="data in libtic">
              <div class="profile">
                <img :src="data.icon" />
                <h4>
                  {{ data.title }}
                  <p class="m-0 p">{{ data.description }}</p>
                </h4>
              </div>
            </div>
            <!--      QRCode      -->
            <!--            <div class="col-12" style="margin-bottom: 15px;">-->
            <!--              <div class="row fcc">-->
            <!--                <div class="col-2">-->
            <!--                  <img class="w-100" :src="qrcode('https://github.com/WeedForJustice/libitc')" >-->
            <!--                </div>-->
            <!--                <div class="col-5 fsc">-->
            <!--                  <p>-->
            <!--                    掃描QRCode或輸入下列網址來訪問原始碼：-->
            <!--                  </p>-->
            <!--                  <p>https://github.com/WeedForJustice/libitc</p>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>

        <!--    vpp    -->
        <div class="col-12">
          <h3 class="section_main_title">
            <strong class="text-nord3">Vpp</strong>
          </h3>
          <p class="">
            準備工科賽時，為了提升開發效率，也讓學弟妹日後更輕鬆的練習，我們利用
            Python 開發 VHDL 預處理器，以 VHDL PreProcessor 取名為 Vpp。
          </p>
          <h5 class="text-nord3">功能與特點</h5>
          <p class="mb-2">
            <strong class="mr-3">&middot;</strong>只需書寫
            <el-tag>--!&lt;指令&gt;</el-tag> 就會自動展開成 VHDL
            程式碼，書寫容易又不會與編譯器衝突
          </p>
          <p class="mb-2">
            <strong class="mr-3">&middot;</strong
            ><el-tag>min</el-tag> 指令縮小程式碼體積，最多加快編譯速度30%
          </p>
          <p class="mb-2">
            <strong class="mr-3">&middot;</strong
            ><el-tag>eval</el-tag> 指令可以用 Python 運算後展開成 VHDL ，補足
            VHDL 語法的不方便
          </p>
          <p class="mb-2">
            <strong class="mr-3">&middot;</strong><el-tag>def</el-tag> 與 `inc`
            指令如 C 以語言的 `define` 和 `include` ，輕鬆打出千字敘述
          </p>
          <p class="mb-2">
            <strong class="mr-3">&middot;</strong><el-tag>pp</el-tag>
            指令選擇區段開關預處理器，有效避免與其他工具的衝突
          </p>
          <p class="mb-2">
            <strong class="mr-3">&middot;</strong
            >預處理器程式碼結構明確、說明完整，容易擴充功能
          </p>
        </div>

        <!--    Vpp  四張圖片   -->
        <div class="col-5 mt-2">
          <img
            :src="require('../assets/itc-code/vpp-before-inc.png')"
            class="w-100 mac-drop-shadow"
          />
        </div>
        <div class="col-7 mt-3">
          <img
            :src="require('../assets/itc-code/vpp-after-inc.png')"
            class="w-100 mac-drop-shadow"
          />
        </div>
        <div class="col-12 fcc">
          <p class="mt-3">
            <i class="el-icon-arrow-up mr-3"></i>
            透過 <strong>inc</strong> 快速匯入程式碼
          </p>
        </div>
        <div class="col-6">
          <img
            :src="require('../assets/itc-code/vpp-before-def.png')"
            style="width: calc(100% + 75px)"
            class="macos-drop-shadow"
          />
          <p class="mt-3">
            <i class="el-icon-arrow-up mr-3"></i>
            透過 <strong>def</strong> 節省繁瑣的打字時間
          </p>
        </div>
        <div class="col-6">
          <img
            :src="require('../assets/itc-code/vpp-after-def.png')"
            style="
              width: calc(100% + 120px);
              margin-left: -120px;
              margin-top: 60px;
            "
            class="macos-drop-shadow"
          />
        </div>
      </div>
    </div>
    <footer>
      <div class="container-fluid">
        <div
          class="row"
          v-bind:style="{
            'flex-direction':
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' row-reverse'
                : 'row',
          }"
        >
          <div
            class="col-11"
            v-bind:class="[
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' fsc'
                : 'fec',
            ]"
          >
            <div>
              <div>
                <!--                <h5 class="m-0 pr-2 mr-2" style="border-right: .1px rgba(0, 0, 0, .3) solid">自傳</h5>-->
                <h5 class="m-0">作品集 | 工科賽開發成果</h5>
              </div>
              <p class="m-0">{{ $store.state.school }}</p>
            </div>
          </div>
          <div class="col-1">
            <h6 class="pageNumber">
              {{
                $router.options.routes
                  .map((e) => e.path)
                  .indexOf(this.$router.currentRoute.path) -
                $store.state.indexStep
              }}
            </h6>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
const fs = require("fs");
const QRCode = require("qrcode");
export default {
  name: "portfolio_itc_1",
  data() {
    return {
      libtic: [
        {
          description: "硬體控制器",
          title: "10種",
          icon: require("../assets/icons/module-icon.png"),
        },
        {
          description: "共同函式庫",
          title: "4個",
          icon: require("../assets/icons/library.svg"),
        },
        {
          description: "串列協定控制器",
          title: "3種",
          icon: require("../assets/icons/serial-port.svg"),
        },
      ],
      vpp: [
        "只需書寫`--!<指令>`就會自動展開成 VHDL 程式碼，書寫容易又不會與編譯器衝突",
        "`min` 指令縮小程式碼體積，最多加快編譯速度30%",
        "`eval` 指令可以用 Python 運算後展開成 VHDL ，補足 VHDL 語法的不方便",
        "`def` 與 `inc` 指令如 C 以語言的 `define` 和 `include` ，輕鬆打出千字敘述",
        "`pp` 指令選擇區段開關預處理器，有效避免與其他工具的衝突",
        "預處理器程式碼結構明確、說明完整，容易擴充功能",
      ],
    };
  },
  methods: {
    qrcode(url) {
      let returnData = null;
      QRCode.toDataURL(
        url,
        {
          margin: 1,
          width: 200,
          height: 200,
        },
        function (err, qrcodeUrl) {
          returnData = qrcodeUrl;
        }
      );
      return returnData;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile_frame {
  padding: 15px;
  border: none;
  background: transparent;
  min-height: 100px;
  text-align: left;
}
.profile {
  width: 100%;
  height: 90px;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  display: flex;
  border-radius: 15px;
  align-items: center;
  box-shadow: 0 0 20px -5px rgba(100, 100, 100, 0.5) !important;
  padding: 15px;
  -webkit-print-color-adjust: exact;
  filter: opacity(1);
}
.profile > img {
  max-width: 60px;
  max-height: 60px;
  min-width: 60px;
  height: auto;
}
.profile > h4 {
  color: #a58a5b;
  margin-left: 15px;
  margin-bottom: 0;
}
.profile > h4 > span {
  color: #676767;
  font-size: 12px;
  font-weight: 400;
}
.profile > h4 > .profile_link > a > span {
  font-size: 16px;
  color: #bfa67a;
}
.profile_link {
  display: flex;
  justify-content: space-between;
}
.badge-skills {
  color: #409eff;
  background-color: #ecf5ff;
  margin: 7.5px 7.5px 7.5px 0;
}

.el-tag {
  font-size: 16px !important;
}
</style>
