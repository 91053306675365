<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 mb-3">
          <div class="section_title mb-2">
            <div class="section_subtitle"><strong>英文</strong></div>
            <h3 class="section_main_title">
              自主學習 <br />
              累積聽說讀寫 交流經驗
            </h3>
          </div>
          <p class="description">
            網際網路尚未發展成今日的規模時，我已經深感興趣。龐大的資料量使我查詢資料如魚得水，但擁有強烈好奇心的我，不想讓知識被語言限制，於網路自學英文。從練習全民英檢的網站，到YouTube、Reddit，
            <strong>英文不再只是學習的利器，更是我的一部分。</strong>
            高中時，我參加英文拔尖班，利用 Postcrossing
            與外國人交換明信片、參加國際特赦組織的寫信馬拉松 (Write for Rights)
            行動，讓我不單學習英文，更累積社會交流的經驗。同時，
            <strong>我只錯一題取得全民英檢中級，更於多益考取960分。</strong>
          </p>
        </div>
      </div>
      <div class="row mb-3 fcc">
        <div class="fcc col-5">
          <img
            :src="require('../assets/英文/IMG_20210516_0022.png')"
            class="img-border"
          />
          <p class="mt-2 fsc text-center">
            <i class="el-icon-arrow-up mr-2"></i>
            Write for Rights
          </p>
        </div>
        <div class="fcc col-7">
          <img
            :src="require('../assets/英文/postcrossing.png')"
            class="img-border"
          />
          <p class="mt-2 fsc text-center">
            <i class="el-icon-arrow-up mr-2"></i>
            Postcrossing 明信片寄出
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="section_title mb-2">
            <div class="section_subtitle"><strong>校外興趣探索</strong></div>
            <h3 class="section_main_title">
              自學架設 Nextcloud、Postfix 等伺服器<br />自己的資料自己管理
            </h3>
          </div>
          <p class="description">
            Google
            雲端硬碟接二連三的出現錯誤，那一天我燃起了自己架設伺服器的鬥志，開始蒐集資料。
            Nextcloud 是雲端硬碟自由軟體，採用 PHP 編寫，功能之豐富可與 Google
            提供的所有服務匹敵。
            Postfix則是最受歡迎的電子郵件伺服器，有著明確的使用文件。即使有龐大的先備知識需要學習，我依然徹夜未眠的設定與調整，最終
            <strong
              >架設了常年運轉、安全的網路服務，至今已服務家人和同學一年以上。</strong
            >
          </p>
        </div>
      </div>
      <div class="row mb-3 fcc">
        <div class="fcc col-5">
          <img :src="require('../assets/技能/nc.png')" class="img-border" />
          <p class="mt-2 fsc text-center">
            <i class="el-icon-arrow-up mr-2"></i>
            Nextcloud 首頁
          </p>
        </div>
        <div class="fcc col-7">
          <img
            :src="require('../assets/技能/postfix.png')"
            class="img-border"
          />
          <p class="mt-2 fsc text-center">
            <i class="el-icon-arrow-up mr-2"></i>
            Postfix 郵件伺服器正常運作
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h5 class="text-center text-nord1">自我強弱危機分析</h5>
          <hr />
          <div class="row">
            <div class="col-3 swot">
              <h5 class="badge text-white bg-primary">優勢 Strengths</h5>
              <ul style="padding-left: 15px">
                <li>
                  <span class="text-nord1"
                    >跨電子、資訊、資管<br />的專業經驗</span
                  >
                </li>
                <li><span class="text-nord1">在時間壓力下表現傑出</span></li>
                <li>
                  <span class="text-nord1"
                    >豐富的語言能力，<br />跨國溝通容易</span
                  >
                </li>
              </ul>
            </div>
            <div class="col-3 swot">
              <h5 class="badge text-nord1 bg-warning">劣勢 Weaknesses</h5>
              <ul style="padding-left: 15px">
                <li>
                  <span class="text-nord1">對無興趣的事物不熱情</span>
                </li>
                <li>
                  <span class="text-nord1">偏好少數科 未顧全大局</span>
                </li>
                <li>
                  <span class="text-nord1"
                    >易因一心追求目標<br />而錯失週遭良機</span
                  >
                </li>
              </ul>
            </div>
            <div class="col-3 swot">
              <h5 class="badge text-white bg-success">機會 Opportunities</h5>
              <ul style="padding-left: 15px">
                <li>
                  <span class="text-nord1"
                    >優秀的同學與師長<br />值得學習和效法</span
                  >
                </li>
                <li>
                  <span class="text-nord1"
                    >電子產業多未整合標準<br />整合後能加速市場發展</span
                  >
                </li>
              </ul>
            </div>
            <div class="col-3 swot">
              <h5 class="badge text-white bg-danger">威脅 Threats</h5>
              <ul style="padding-left: 15px">
                <li>
                  <span class="text-nord1"
                    >技術變遷快速<br />不斷學習才能跟上時代</span
                  >
                </li>
                <li>
                  <span class="text-nord1"
                    >COVID-19 疫情無法到校<br />降低學習效率</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="container-fluid">
        <div
          class="row"
          v-bind:style="{
            'flex-direction':
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' row-reverse'
                : 'row',
          }"
        >
          <div
            class="col-11"
            v-bind:class="[
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' fsc'
                : 'fec',
            ]"
          >
            <div>
              <div>
                <!--                <h5 class="m-0 pr-2 mr-2" style="border-right: .1px rgba(0, 0, 0, .3) solid">自傳</h5>-->
                <h5 class="m-0">自傳</h5>
              </div>
              <p class="m-0">{{ $store.state.school }}</p>
            </div>
          </div>
          <div class="col-1">
            <h6 class="pageNumber">
              {{
                $router.options.routes
                  .map((e) => e.path)
                  .indexOf(this.$router.currentRoute.path) -
                $store.state.indexStep
              }}
            </h6>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "bio_2",
};
</script>

<style lang="scss" scoped>
.swot {
  border-right: 0.1px rgba(0, 0, 0, 0.1) solid;
}
.swot:last-child {
  border: unset;
}
.el-icon-right {
  position: absolute;
  right: -7.5px;
}
</style>
