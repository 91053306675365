<template>
  <div class="a4" id="app" style="height: 100vh; overflow: auto">
    <router-view> </router-view>
  </div>
</template>
<script>
import router from "@/router";
import "nord-ui";
export default {
  name: "app",
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.code == "ArrowUp") this.pageUp();
      else if (e.code == "ArrowDown") this.pageDown();
    });
  },
  methods: {
    pageDown() {
      let route_list = this.$router.options.routes;
      let current_index = route_list
        .map((e) => e.path)
        .indexOf(this.$router.currentRoute.path);
      if (current_index >= route_list.length - 1) this.$router.push("/");
      else this.$router.push(route_list[current_index + 1].path);

      console.log(
        this.$router.options.routes
          .map((e) => e.path)
          .indexOf(this.$router.currentRoute.path) - 3
      );
    },
    pageUp() {
      let route_list = this.$router.options.routes;
      let current_index = route_list
        .map((e) => e.path)
        .indexOf(this.$router.currentRoute.path);
      if (current_index <= 0)
        this.$router.push(route_list[route_list.length - 1].path);
      else this.$router.push(route_list[current_index - 1].path);
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif !important;
}
body {
  background: white !important;
}
img {
  -webkit-print-color-adjust: exact;
  -webkit-filter: opacity(1) !important;
}
.shadow {
  -webkit-print-color-adjust: exact;
  -webkit-filter: opacity(1);
}
.a4 > div {
  padding: 2.7cm 3.2cm;
  width: 100%;
  max-width: 1130px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.badge {
  border: unset !important;
  padding: 3px 7.5px !important;
  font-size: 50% !important;
  line-height: unset !important;
}
text-white {
  color: white !important;
}
.fcc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.fac {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.fbc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.fec {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.fsc {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
#footer {
  position: fixed;
  bottom: -30px;
  left: 0;
  right: 0;
  text-align: center;
  color: #999;
}
p,
.p {
  font-size: 18px;
  color: #2e3440;
  line-height: 1.5em;
}
.description {
  text-indent: 2em;
}
.col-20 {
  flex: 0 0 20%;
  max-width: 20%;
}
hr {
  border-top: 0.5px solid rgba(0, 0, 0, 0.2) !important;
}
.img-border {
  width: 100%;
  border: 15px white solid;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}
.el-alert__title {
  font-size: 18px !important;
}
h4 {
  font-weight: 700;
}
.macos-drop-shadow {
  filter: drop-shadow(0 12px 15px rgba(0, 0, 0, 0.38)) !important;
}

.section_subtitle {
  margin-bottom: 7px;
  padding-left: 7px;
  font-size: 20px;
  line-height: 1;
  color: #81a1c1;
  border-left: 3px solid #5e81ac;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
}

/*-----------按鈕（for About As ）-------------*/
.btn-color-background {
  user-select: none;
  background-color: #bfa67a;
  border: 1px solid #bfa67a;
  border-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857;
  margin-bottom: 0;
  padding: 7px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
}
.home-button a:hover {
  margin-right: 20px;
  margin: 30px 0;
  border-radius: 5px;
  font-size: 18px;
  padding: 10px 20px;
  background: #bfa67a;
  color: white;
  box-shadow: 1px 1px 10px 0px rgba(221, 221, 221);
}
.home-button > a {
  margin-right: 20px;
  margin: 30px 0;
  border-radius: 5px;
  font-size: 18px;
  padding: 10px 20px;
  background: transparent;
  color: #bfa67a;
}
.section_main_title {
  color: #2e3440;
}
strong {
  color: #5e81ac;
}
footer {
  position: fixed;
  bottom: 7.5px;
  width: 100%;
}
footer .row {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}
footer .col-11 {
  padding-right: 0;
}
.pageNumber {
  background: #ecf5ff;
  color: #409eff;
  padding: 25px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  font-weight: 200;
}
.bg-primary {
  background: #8fbcbb !important;
}
.bg-success {
  background: #a3be8c !important;
}
.bg-danger {
  background: #bf616a !important;
}
.bg-warning {
  background: #d08770 !important;
}
.bg-info {
  background: #5e81ac !important;
}
.bg-secondary {
  background: #3b4252 !important;
}
.badge-success {
  color: white !important;
}
.badge {
  font-size: 18px !important;
}
.badge-skills {
  color: #409eff;
  background-color: #ecf5ff;
  margin: 7.5px 7.5px 7.5px 0;
}
</style>
