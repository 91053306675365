<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 fcc">
          <img
            :src="
              require('../assets/FPGATools/108電路板_數位電子_含有描述_画板.jpg')
            "
            style="width: 95%"
            class="shadow"
          />
          <p class="mt-3 w-100 text-center">
            <i class="el-icon-arrow-up mr-3"></i>
            我們重新設計比賽用的電路版結構，並且開發易於除錯的圖紙，節省除錯時間
          </p>
        </div>
      </div>
      <!--   相關文件與連結   -->
      <div class="row mt-2">
        <div class="col-12">
          <h5 class="mb-3 text-center">相關原始碼連結</h5>
          <div class="row">
            <div class="col-20 fcc qrcode" v-for="data in urls">
              <img :src="qrcode(data.url)" class="w-75 shadow" />
              <p class="mt-3 w-100 text-center">{{ data.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="container-fluid">
        <div
          class="row"
          v-bind:style="{
            'flex-direction':
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' row-reverse'
                : 'row',
          }"
        >
          <div
            class="col-11"
            v-bind:class="[
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' fsc'
                : 'fec',
            ]"
          >
            <div>
              <div>
                <!--                <h5 class="m-0 pr-2 mr-2" style="border-right: .1px rgba(0, 0, 0, .3) solid">自傳</h5>-->
                <h5 class="m-0">作品集 | 工科賽開發成果</h5>
              </div>
              <p class="m-0">{{ $store.state.school }}</p>
            </div>
          </div>
          <div class="col-1">
            <h6 class="pageNumber">
              {{
                $router.options.routes
                  .map((e) => e.path)
                  .indexOf(this.$router.currentRoute.path) -
                $store.state.indexStep
              }}
            </h6>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
const fs = require("fs");
const QRCode = require("qrcode");
export default {
  name: "portfolio_itc_4",
  data() {
    return {
      urls: [
        {
          name: "libitc",
          url: "https://github.com/WeedForJustice/libitc",
        },
        {
          name: "Vpp",
          url: "https://github.com/WeedForJustice/libitc/blob/develop/tools/pp.py",
        },
        {
          name: "GenMIF",
          url: "https://github.com/WeedForJustice/libitc/blob/develop/tools/genmif.py",
        },
        {
          name: "Tcl 腳本",
          url: "https://github.com/WeedForJustice/libitc/blob/develop/tools/compile.tcl",
        },
        {
          name: "FPGA Tools",
          url: "https://github.com/ChengHung-Wang/FPGA-Tools",
        },
      ],
    };
  },
  methods: {
    qrcode(url) {
      let returnData = null;
      QRCode.toDataURL(
        url,
        {
          margin: 1,
          width: 200,
          height: 200,
        },
        function (err, qrcodeUrl) {
          returnData = qrcodeUrl;
        }
      );
      return returnData;
    },
  },
};
</script>
