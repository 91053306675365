<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="section_title mb-2">
            <h1 class="mb-4 text-n"><strong>讀書計畫</strong></h1>
            <h3 class="section_main_title">集學科、術科知識，強化創作能力</h3>
          </div>
          <p class="description mb-4">
            於準備工科賽期間，我對硬體描述語言更加熟悉，也從此對IC設計興致勃勃。在練習的期間，我發現能夠將軟硬體整合是制勝的關鍵之一，但是我使用的VHDL硬體描述語言由於語法冗長而不易入門，Verilog也因不需精確描述而不易除錯，所以我使用Python製作了一系列的VHDL前置處理器，加快開發的速度。可程式邏輯是電子產業不可或缺的技術，硬體描述語言卻成為了發掘人才的障礙，因此我下定決心開發新的語言，降低學習IC設計的門檻。接下來的4年期間，我將目標設為加強學科能力，以及完成新硬體語言的雛形。
          </p>
          <el-timeline>
            <el-timeline-item
              v-for="data in timestamp"
              :timestamp="data.start + ' ~ ' + data.end"
              placement="top"
            >
              <el-card>
                <h4>{{ data.title }}</h4>
                <ul style="padding-left: 30px">
                  <li v-for="item in data.things" class="text-nord3 mb-2">
                    <span class="p">{{ item }}</span>
                  </li>
                </ul>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
    <footer>
      <div class="container-fluid">
        <div
          class="row"
          v-bind:style="{
            'flex-direction':
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' row-reverse'
                : 'row',
          }"
        >
          <div
            class="col-11"
            v-bind:class="[
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' fsc'
                : 'fec',
            ]"
          >
            <div>
              <div>
                <!--                <h5 class="m-0 pr-2 mr-2" style="border-right: .1px rgba(0, 0, 0, .3) solid">自傳</h5>-->
                <h5 class="m-0">讀書計劃</h5>
              </div>
              <p class="m-0">{{ $store.state.school }}</p>
            </div>
          </div>
          <div class="col-1">
            <h6 class="pageNumber">
              {{
                $router.options.routes
                  .map((e) => e.path)
                  .indexOf(this.$router.currentRoute.path) -
                $store.state.indexStep
              }}
            </h6>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "sop",
  data() {
    return {
      timestamp: [
        {
          title: "暑假期間",
          things: [
            "完成51屆全國技能競賽",
            "研究業界 IC 設計的工具使用狀況",
            "加強Python、Rust與VHDL能力",
          ],
          start: "2021-06",
          end: "2021-09",
        },
        {
          title: "大一",
          things: [
            "補足數學能力，奠定學科根基",
            "在GitHub上參與開源專案，累積與外國同好的交流經驗",
            "詳閱林銘波教授的《微算機原理與應用》熟悉微處理機的設計觀念及工作原理",
          ],
          start: "2021-09",
          end: "2022-09",
        },
        {
          title: "大二",
          things: [
            "選修數位系統設計，學習如何設計CPU架構及其組合語言",
            "選修溝通及口語表達訓練，增強表達能力",
            "開始研究開發新硬體語言的可能性，並著手進行設計",
          ],
          start: "2022-09",
          end: "2023-09",
        },
        {
          title: "大三",
          things: [
            "參加嵌入式系統設計課程，研究單晶片I/O的設計思路，應用於IC設計中",
            "學習編譯器與轉譯器的工作原理",
            "啟動新硬體語言開發專案，製作與現存硬體語言的轉譯器",
          ],
          start: "2023-09",
          end: "2024-09",
        },
        {
          title: "大四",
          things: [
            "編寫新硬體語言的編譯器，將其實作開放原始碼",
            "製作部屬系統，支援對現有FPGA/CPLD規劃電路",
            "準備就讀研究所，持續進修專業能力",
          ],
          start: "2024-09",
          end: "2025-06",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
