<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 mb-3">
          <h1 class="mb-4 text-n">
            <strong>自傳</strong>
          </h1>
          <div class="section_title mb-2">
            <div class="section_subtitle"><strong>背景</strong></div>
            <h3 class="section_main_title">
              耳濡目染造就夢想，勤勞不懈貢獻社會
            </h3>
          </div>
          <p class="description">
            我是承軒，畢業自國立彰師附工，來自嘉義。父母經營零售業，受進銷存（POS）軟體設計而需操作與維修老舊電腦，使我從小就埋下夢想的種子：
            <strong>
              製作更好的POS系統，改善父母的工作效率，減輕他們的負擔
            </strong>
            。十年前，我在筆記本上畫著我設計的界面和功能給父母親看，十年後，我仍以此夢想為基礎，接觸更多領域的專業技術；
            <strong> 為了貢獻於自由專案，不斷地力求進步。</strong>
          </p>
        </div>

        <div class="col-12 mb-3 fac">
          <div class="row">
            <div class="fcc col-3">
              <img
                style="width: 130px"
                :src="require('../assets/icons/努力向上.svg')"
              />
              <p style="max-width: 130px" class="w-100 mt-2 text-center">
                勤奮向上
              </p>
            </div>
            <div class="fcc col-3">
              <img
                style="width: 130px"
                :src="require('../assets/icons/英語能力.svg')"
              />
              <p style="max-width: 130px" class="w-100 mt-2 text-center">
                高英文水平
              </p>
            </div>
            <div class="fcc col-3">
              <img
                style="width: 130px"
                :src="require('../assets/icons/樂於助人.svg')"
              />
              <p style="max-width: 130px" class="w-100 mt-2 text-center">
                樂於分享幫助他人
              </p>
            </div>
            <div class="fcc col-3">
              <img
                style="width: 130px"
                :src="require('../assets/icons/領導特質.svg')"
              />
              <p style="max-width: 130px" class="w-100 mt-2 text-center">
                領導特質
              </p>
            </div>
          </div>
        </div>

        <div class="col-sm-12" mb-3>
          <div class="section_title mb-2">
            <div class="section_subtitle">
              <strong>專業科目、實習、競賽</strong>
            </div>
            <h3 class="section_main_title">夜以繼日學習，獲得大量經驗</h3>
          </div>
          <p class="description">
            古人曰：「工欲善其事，必先利其器。」自從高二，我把握成為選手的機會，利用學校的資源增加練習的時間，閱讀老師們推薦的書籍增強電子電路的知識，成為選手之中最為勤奮的一員。
            準備工科賽時，我總是最早報到，最晚離開，除了開發小綠人動畫等迷你專案作為練習，也與它校的同學一同開發網頁、電路等計劃來增強自己的實力。
          </p>
          <p class="description">
            <strong
              >首次參加全國技能競賽應用電子，我於分區賽拿下第6名佳作，隨後於工科賽獲得第11名優勝，接著在第二次分區賽中得到第4名，獲得全國技能競賽決賽的參賽權。</strong
            >
          </p>
          <div class="row mt-3 mb-3">
            <div class="col-4 fcc">
              <img
                class="img-border"
                :src="require('../assets/技能/IMG_20210516_0007.png')"
              />
              <p class="fcc mt-3">
                <i class="el-icon-arrow-up mr-1"></i>
                50屆全國技能競賽中區分區賽<br />佳作
              </p>
            </div>
            <div class="col-4">
              <img
                class="img-border"
                :src="require('../assets/技能/IMG_20210524_0009.png')"
              />
              <p class="fcc mt-3">
                <i class="el-icon-arrow-up mr-1"></i>
                51屆全國技能競賽中區分區賽<br />第四名
              </p>
            </div>
            <div class="col-4">
              <img
                class="img-border"
                :src="require('../assets/技能/IMG_20210516_0019.png')"
              />
              <p class="fcc mt-3">
                <i class="el-icon-arrow-up mr-1"></i>
                109全國高中技藝競賽<br />數位電子 優勝第11名
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="container-fluid">
        <div
          class="row"
          v-bind:style="{
            'flex-direction':
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' row-reverse'
                : 'row',
          }"
        >
          <div
            class="col-11"
            v-bind:class="[
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' fsc'
                : 'fec',
            ]"
          >
            <div>
              <div>
                <!--                <h5 class="m-0 pr-2 mr-2" style="border-right: .1px rgba(0, 0, 0, .3) solid">自傳</h5>-->
                <h5 class="m-0">自傳</h5>
              </div>
              <p class="m-0">{{ $store.state.school }}</p>
            </div>
          </div>
          <div class="col-1">
            <h6 class="pageNumber">
              {{
                $router.options.routes
                  .map((e) => e.path)
                  .indexOf(this.$router.currentRoute.path) -
                $store.state.indexStep
              }}
            </h6>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "bio_1",
};
</script>

<style lang="scss" scoped></style>
