<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="section_title mb-2">
            <div class="section_subtitle">
              <strong> 小論文 </strong>
            </div>
            <h3 class="section_main_title">
              <strong class="text-nord3">智慧家庭市場分裂化之改善研究</strong>
            </h3>
          </div>
          <p class="mb-1 description">
            在這個由方便性驅動的經濟市場，人們開始尋求使自己生活更加便利的方法，於是市場上出現五花八門的智慧家庭產品，吸引消費者的注意力。
            如今科技進步和消費者需求增加使得品牌之間的競爭使得數以百計的商業生態系統產生，限制了消費者選擇產品的自由，開發人員也由於沒有標準，無法發揮創意。
            <strong
              >「智慧家庭市場分裂化之改善研究」是我為了研究市場的實際問題、提出共通開放的標準，降低入門門檻製作的小論文。</strong
            >
          </p>
          <p class="mb-0 description">
            內容包含智慧家庭的發展史、消費者問卷調查、以及兩種完整的解決提案。有
            14 項來自世界各地的參考資料作為論證，共 5737 個字。在我與 2
            位同學並肩合作之下，
            <strong
              >成功在第 1100315 梯次小論文寫作比賽獲得「優等」獎項。</strong
            >
          </p>
        </div>
      </div>
      <div class="row mt-3 mb-3" style="flex-wrap: nowrap">
        <div class="col-6">
          <img
            style="background: white"
            class="shadow"
            :src="require('../assets/小論文/書影/p4.png')"
          />
        </div>
        <div class="col-6" style="margin-left: -33.33333%">
          <img
            style="background: white"
            class="shadow"
            :src="require('../assets/小論文/書影/p5.png')"
          />
        </div>
        <div class="col-6" style="margin-left: -33.33333%">
          <img
            style="background: white"
            class="shadow"
            :src="require('../assets/小論文/書影/p7.png')"
          />
        </div>
        <div class="col-6" style="margin-left: -33.33333%">
          <img
            style="background: white"
            class="shadow"
            :src="require('../assets/小論文/書影/p8.png')"
          />
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <p class="w-100 mt-2 fcc text-center">
            <i class="el-icon-arrow-up mr-2"></i>
            「智慧家庭市場分裂化之改善研究」書影
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <img
            :src="require('../assets/小論文/award.jpg')"
            class="img-border"
          />
        </div>
        <div class="col-4">
          <p class="w-100 mt-2 fsc text-center">
            <i class="el-icon-arrow-left mr-2"></i>
            小論文 優等
          </p>
          <div class="fec w-75 qrcode" style="margin-top: 100px">
            <img
              :src="
                qrcode(
                  'https://drive.google.com/file/d/1eM54uOlTXUqh8z1UkcDGLjEPBICXfsfN/view?usp=sharing'
                )
              "
              class="image-border"
            />
            <p class="mt-3 w-100 text-center">
              <i class="el-icon-arrow-up mr-2"></i>
              完整小論文 PDF
            </p>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="container-fluid">
        <div
          class="row"
          v-bind:style="{
            'flex-direction':
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' row-reverse'
                : 'row',
          }"
        >
          <div
            class="col-11"
            v-bind:class="[
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' fsc'
                : 'fec',
            ]"
          >
            <div>
              <div>
                <h5 class="m-0">作品集 | 小論文</h5>
              </div>
              <p class="m-0">{{ $store.state.school }}</p>
            </div>
          </div>
          <div class="col-1">
            <h6 class="pageNumber">
              {{
                $router.options.routes
                  .map((e) => e.path)
                  .indexOf(this.$router.currentRoute.path) -
                $store.state.indexStep
              }}
            </h6>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
const QRCode = require("qrcode");
export default {
  name: "portfolio_essay",
  methods: {
    qrcode(url) {
      let returnData = null;
      QRCode.toDataURL(
        url,
        {
          margin: 1,
          width: 200,
          height: 200,
        },
        function (err, qrcodeUrl) {
          returnData = qrcodeUrl;
        }
      );
      return returnData;
    },
  },
};
</script>
