<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="section_title mb-2">
            <div class="section_subtitle"><strong>學習成就</strong></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <img
              :src="require('../assets/英文/IMG_20210516_0027.png')"
              class="img-border"
            />
            <p class="w-100 mt-2 fsc text-center">
              <i class="el-icon-arrow-up mr-2"></i>
              全民英檢 中級
            </p>
          </div>
          <div class="col-6">
            <img
              :src="require('../assets/英文/IMG_20210516_0026.png')"
              class="img-border"
            />
            <p class="w-100 mt-2 fsc text-center">
              <i class="el-icon-arrow-up mr-2"></i>
              全民英檢 初級
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-10 fcc">
            <img
              :src="require('../assets/英文/IMG_20210516_0003.png')"
              class="img-border"
            />
            <p class="w-100 mt-2 fcc text-center">
              <i class="el-icon-arrow-up mr-2"></i>
              TOEIC <strong class="text-nord1 ml-2">960分金色證書</strong>
            </p>
          </div>
          <div class="col-2" style="margin: auto">
            <p>
              聽力<strong style="color: #d08770">滿分</strong><br />
              <strong style="font-size: 2rem; color: #d08770">490</strong>/490
            </p>
            <p>
              閱讀<br />
              <strong style="font-size: 2rem; color: #d08770">470</strong>/490
            </p>
            <p>
              總分<br />
              <strong style="font-size: 2rem; color: #d08770">960</strong>/990
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-3 fcc">
            <img
              :src="require('../assets/英文/IMG_20210516_0008.png')"
              class="img-border"
            />
            <p class="w-100 mt-2 fcc text-center">
              <i class="el-icon-arrow-up mr-2"></i>
              英文學科競試<br />三年級組第2名
            </p>
          </div>
          <div class="col-3 fcc">
            <img
              :src="require('../assets/英文/IMG_20210516_0011.png')"
              class="img-border"
            />
            <p class="w-100 mt-2 fcc text-center">
              <i class="el-icon-arrow-up mr-2"></i>
              英文學科競試<br />二年級組第8名
            </p>
          </div>
          <div class="col-3 fcc">
            <img
              :src="require('../assets/英文/IMG_20210516_0014.png')"
              class="img-border"
            />
            <p class="w-100 mt-2 fcc text-center">
              <i class="el-icon-arrow-up mr-2"></i>
              於彰工青年「斜槓」<br />獲得刊登發表
            </p>
          </div>
          <div class="col-3 fcc">
            <img
              :src="require('../assets/英文/IMG_20210516_0024.png')"
              class="img-border"
            />
            <p class="w-100 mt-2 fcc text-center">
              <i class="el-icon-arrow-up mr-2"></i>
              於彰工青年「文青」<br />獲得刊登發表
            </p>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="container-fluid">
        <div
          class="row"
          v-bind:style="{
            'flex-direction':
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' row-reverse'
                : 'row',
          }"
        >
          <div
            class="col-11"
            v-bind:class="[
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' fsc'
                : 'fec',
            ]"
          >
            <div>
              <div>
                <h5 class="m-0">證照及獲獎</h5>
              </div>
              <p class="m-0">{{ $store.state.school }}</p>
            </div>
          </div>
          <div class="col-1">
            <h6 class="pageNumber">
              {{
                $router.options.routes
                  .map((e) => e.path)
                  .indexOf(this.$router.currentRoute.path) -
                $store.state.indexStep
              }}
            </h6>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "cert",
};
</script>
