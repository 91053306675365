var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._m(0),_c('p',{staticClass:"description mb-4"},[_vm._v(" 於準備工科賽期間，我對硬體描述語言更加熟悉，也從此對IC設計興致勃勃。在練習的期間，我發現能夠將軟硬體整合是制勝的關鍵之一，但是我使用的VHDL硬體描述語言由於語法冗長而不易入門，Verilog也因不需精確描述而不易除錯，所以我使用Python製作了一系列的VHDL前置處理器，加快開發的速度。可程式邏輯是電子產業不可或缺的技術，硬體描述語言卻成為了發掘人才的障礙，因此我下定決心開發新的語言，降低學習IC設計的門檻。接下來的4年期間，我將目標設為加強學科能力，以及完成新硬體語言的雛形。 ")]),_c('el-timeline',_vm._l((_vm.timestamp),function(data){return _c('el-timeline-item',{attrs:{"timestamp":data.start + ' ~ ' + data.end,"placement":"top"}},[_c('el-card',[_c('h4',[_vm._v(_vm._s(data.title))]),_c('ul',{staticStyle:{"padding-left":"30px"}},_vm._l((data.things),function(item){return _c('li',{staticClass:"text-nord3 mb-2"},[_c('span',{staticClass:"p"},[_vm._v(_vm._s(item))])])}),0)])],1)}),1)],1)])]),_c('footer',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row",style:({
          'flex-direction':
            (_vm.$router.options.routes
              .map(function (e) { return e.path; })
              .indexOf(_vm.$router.currentRoute.path) -
              _vm.$store.state.indexStep) %
              2 ==
            1
              ? ' row-reverse'
              : 'row',
        })},[_c('div',{staticClass:"col-11",class:[
            (_vm.$router.options.routes
              .map(function (e) { return e.path; })
              .indexOf(_vm.$router.currentRoute.path) -
              _vm.$store.state.indexStep) %
              2 ==
            1
              ? ' fsc'
              : 'fec' ]},[_c('div',[_vm._m(1),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.$store.state.school))])])]),_c('div',{staticClass:"col-1"},[_c('h6',{staticClass:"pageNumber"},[_vm._v(" "+_vm._s(_vm.$router.options.routes .map(function (e) { return e.path; }) .indexOf(this.$router.currentRoute.path) - _vm.$store.state.indexStep)+" ")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section_title mb-2"},[_c('h1',{staticClass:"mb-4 text-n"},[_c('strong',[_vm._v("讀書計畫")])]),_c('h3',{staticClass:"section_main_title"},[_vm._v("集學科、術科知識，強化創作能力")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"m-0"},[_vm._v("讀書計劃")])])}]

export { render, staticRenderFns }