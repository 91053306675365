<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="section_title mb-2">
            <h1 class="mb-4 text-n"><strong>證照及獲獎</strong></h1>
            <div class="section_subtitle"><strong>專業</strong></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <img
            :src="require('../assets/技能/class_b.png')"
            class="img-border"
          />
          <p class="w-100 mt-2 fsc text-center">
            <i class="el-icon-arrow-up mr-2"></i>
            數位電子 乙級證照
          </p>
        </div>
        <div class="col-6">
          <img
            :src="require('../assets/技能/class_c.png')"
            class="img-border"
          />
          <p class="w-100 mt-2 fsc text-center">
            <i class="el-icon-arrow-up mr-2"></i>
            工業電子 丙級證照
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <img
            :src="require('../assets/技能/training_arm.png')"
            class="img-border"
          />
          <p class="w-100 mt-2 fcc text-center">
            <i class="el-icon-arrow-up mr-2"></i>
            ARM 單晶片微處理機<br />專業知能研習 6小時
          </p>
        </div>
        <div class="col-4">
          <img
            :src="require('../assets/技能/training_summer_108.png')"
            class="img-border"
          />
          <p class="w-100 mt-2 fcc text-center">
            <i class="el-icon-arrow-up mr-2"></i>
            暑期技能訓練營<br />108學年度
          </p>
        </div>
        <div class="col-4">
          <img
            :src="require('../assets/技能/training_summer_107.png')"
            class="img-border"
          />
          <p class="w-100 mt-2 fcc text-center">
            <i class="el-icon-arrow-up mr-2"></i>
            暑期技能訓練營<br />107學年度
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="section_title mb-2">
            <div class="section_subtitle"><strong>幹部</strong></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 fcc">
          <img
            :src="require('../assets/幹部/IMG_20210516_0013.png')"
            style="width: 80%"
            class="img-border"
          />
          <p class="w-100 mt-2 fcc text-center">
            <i class="el-icon-arrow-up mr-2"></i>
            擔任二年級上學期副班長
          </p>
        </div>
        <div class="col-6 fcc">
          <img
            :src="require('../assets/幹部/IMG_20210516_0009.png')"
            style="width: 80%"
            class="img-border"
          />
          <p class="w-100 mt-2 fcc text-center">
            <i class="el-icon-arrow-up mr-2"></i>
            擔任二年級下學期風紀班長
          </p>
        </div>
      </div>
    </div>
    <footer>
      <div class="container-fluid">
        <div
          class="row"
          v-bind:style="{
            'flex-direction':
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' row-reverse'
                : 'row',
          }"
        >
          <div
            class="col-11"
            v-bind:class="[
              ($router.options.routes
                .map((e) => e.path)
                .indexOf($router.currentRoute.path) -
                $store.state.indexStep) %
                2 ==
              1
                ? ' fsc'
                : 'fec',
            ]"
          >
            <div>
              <div>
                <h5 class="m-0">證照及獲獎</h5>
              </div>
              <p class="m-0">{{ $store.state.school }}</p>
            </div>
          </div>
          <div class="col-1">
            <h6 class="pageNumber">
              {{
                $router.options.routes
                  .map((e) => e.path)
                  .indexOf(this.$router.currentRoute.path) -
                $store.state.indexStep
              }}
            </h6>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "cert",
};
</script>
