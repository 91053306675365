<template>
  <div id="app" style="align-items: flex-start">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h1>目錄</h1>
          <p>{{ $store.state.school }}</p>
          <hr style="border-top: 0.5px #ebeef5 solid !important" />
        </div>
        <div v-for="(data, index) in plist" class="col-12" :key="index">
          <div class="row" style="align-items: flex-start">
            <div class="col-1">
              <h2>
                <strong style="color: #404244">{{ data.pages }}</strong>
              </h2>
            </div>
            <div class="col-11">
              <h3 style="margin-top: 2.5px">{{ data.name }}</h3>
              <div class="fsc" v-for="item in data.subtitle">
                <span
                  style="
                    width: 60px;
                    font-size: 18px !important;
                    font-weight: 300;
                    padding: 0 7.5px !important;
                  "
                  class="badge fcc badge-pill badge-skills mr-3"
                >
                  {{ item.pageNumber }}
                </span>
                <span class="p" style="font-size: 18px">{{ item.name }}</span>
              </div>
            </div>
            <div class="col-11 offset-1">
              <hr
                style="border-top: 0.5px #ebeef5 solid !important"
                v-if="index < plist.length - 1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "toc",
  data() {
    return {
      plist: [
        {
          name: "簡歷",
          subtitle: [],
          pages: 1,
        },
        {
          name: "自傳",
          subtitle: [],
          pages: 2,
        },
        {
          name: "讀書計畫",
          subtitle: [],
          pages: 4,
        },
        {
          name: "作品集",
          subtitle: [
            {
              name: "小論文",
              pageNumber: 5,
            },
            {
              name: "工科賽開發成果",
              pageNumber: 6,
            },
            {
              name: "新硬體描述語言計劃",
              pageNumber: 10,
            },
          ],
          pages: 5,
        },
        {
          name: "證照與獲獎",
          subtitle: [],
          pages: 11,
        },
        {
          name: "在校成績單",
          subtitle: [],
          pages: 13,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
