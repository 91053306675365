var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row fsc"},[_c('div',{staticClass:"col-2"},[_c('img',{staticClass:"w-100",attrs:{"src":require('../assets/icons/albums.svg')}})]),_c('div',{staticClass:"col-10"},[_vm._m(0),_c('p',{},[_vm._v(" 開發TFT LCD模組的控制器時，我透過白皮書發現將圖片儲存於RAM中效率更好。Quartus軟體中設定記憶體內容需要用不易編寫的MIF檔案，因此我開發了GenMIF軟體，大幅提升轉換圖片的效率。 ")]),_c('hr'),_vm._m(1),_c('p',{staticClass:"mb-2"},[_c('strong',{staticClass:"mr-3"},[_vm._v("·")]),_vm._v(" 輸入 "),_c('el-tag',[_vm._v("JPG, PNG, BMP, GIF, SVG")]),_vm._v(" 等圖片格式皆可轉檔 ")],1),_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"row fcc mt-4"},[_c('div',{staticClass:"col-6 mt-5"},[_c('img',{staticClass:"w-100 macos-drop-shadow",attrs:{"src":require('../assets/itc-code/PM5544.png')}}),_c('p',{staticClass:"mt-5 fcc"},[_vm._v("GenMIF 立即產生可供編譯的程式碼")])]),_vm._m(4),_c('div',{staticClass:"col-5"},[_c('img',{staticClass:"w-100 shadow",attrs:{"src":require('../assets/itc-code/genmif.png')}})])]),_c('div',{staticClass:"row fsc mt-5"},[_c('div',{staticClass:"col-2"},[_c('img',{staticClass:"w-100",attrs:{"src":require('../assets/software-icons/vscode.svg')}})]),_c('div',{staticClass:"col-10"},[_vm._m(5),_vm._m(6),_c('el-steps',{staticClass:"mt-3",attrs:{"active":3,"simple":""}},[_c('el-step',{attrs:{"title":"撰寫程式","icon":"el-icon-edit"}}),_c('el-step',{attrs:{"title":"編譯","icon":"el-icon-s-tools"}}),_c('el-step',{attrs:{"title":"FPGA燒錄","icon":"el-icon-cpu"}})],1)],1)])]),_c('footer',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row",style:({
          'flex-direction':
            (_vm.$router.options.routes
              .map(function (e) { return e.path; })
              .indexOf(_vm.$router.currentRoute.path) -
              _vm.$store.state.indexStep) %
              2 ==
            1
              ? ' row-reverse'
              : 'row',
        })},[_c('div',{staticClass:"col-11",class:[
            (_vm.$router.options.routes
              .map(function (e) { return e.path; })
              .indexOf(_vm.$router.currentRoute.path) -
              _vm.$store.state.indexStep) %
              2 ==
            1
              ? ' fsc'
              : 'fec' ]},[_c('div',[_vm._m(7),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.$store.state.school))])])]),_c('div',{staticClass:"col-1"},[_c('h6',{staticClass:"pageNumber"},[_vm._v(" "+_vm._s(_vm.$router.options.routes .map(function (e) { return e.path; }) .indexOf(this.$router.currentRoute.path) - _vm.$store.state.indexStep)+" ")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"section_main_title"},[_c('strong',{staticClass:"text-nord3"},[_vm._v("GenMIF")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-2"},[_c('strong',{staticClass:"mr-3"},[_vm._v("·")]),_vm._v(" 接收標準 POSIX 指令，可以配合其他程式 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-2"},[_c('strong',{staticClass:"mr-3"},[_vm._v("·")]),_vm._v(" 自動縮放裁剪，可以選擇填滿或全螢幕 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-2"},[_c('strong',{staticClass:"mr-3"},[_vm._v("·")]),_vm._v(" 可視空間需求輸出1-bit雙色、8-bit節省空間或24-bit全彩圖片 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-1 fcc"},[_c('i',{staticClass:"el-icon-arrow-right",staticStyle:{"font-size":"24px","font-weight":"900"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"section_main_title"},[_c('strong',{staticClass:"text-nord3"},[_vm._v("VS Code Tcl 腳本")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{},[_vm._v(" 不僅賽中分秒必爭，練習也一樣。我們上網自學 VS Code 的 API 以及如何利用 Tcl 自動化 Quartus 編譯，寫出了 "),_c('strong',{staticClass:"text-nord3"},[_vm._v(" 能夠一鍵完成例行工作的腳本 ")]),_vm._v(" 。 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"m-0"},[_vm._v("作品集 | 工科賽開發成果")])])}]

export { render, staticRenderFns }