var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 fcc"},[_c('img',{staticClass:"shadow",staticStyle:{"width":"95%"},attrs:{"src":require('../assets/FPGATools/108電路板_數位電子_含有描述_画板.jpg')}}),_vm._m(0)])]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"mb-3 text-center"},[_vm._v("相關原始碼連結")]),_c('div',{staticClass:"row"},_vm._l((_vm.urls),function(data){return _c('div',{staticClass:"col-20 fcc qrcode"},[_c('img',{staticClass:"w-75 shadow",attrs:{"src":_vm.qrcode(data.url)}}),_c('p',{staticClass:"mt-3 w-100 text-center"},[_vm._v(_vm._s(data.name))])])}),0)])])]),_c('footer',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row",style:({
          'flex-direction':
            (_vm.$router.options.routes
              .map(function (e) { return e.path; })
              .indexOf(_vm.$router.currentRoute.path) -
              _vm.$store.state.indexStep) %
              2 ==
            1
              ? ' row-reverse'
              : 'row',
        })},[_c('div',{staticClass:"col-11",class:[
            (_vm.$router.options.routes
              .map(function (e) { return e.path; })
              .indexOf(_vm.$router.currentRoute.path) -
              _vm.$store.state.indexStep) %
              2 ==
            1
              ? ' fsc'
              : 'fec' ]},[_c('div',[_vm._m(1),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.$store.state.school))])])]),_c('div',{staticClass:"col-1"},[_c('h6',{staticClass:"pageNumber"},[_vm._v(" "+_vm._s(_vm.$router.options.routes .map(function (e) { return e.path; }) .indexOf(this.$router.currentRoute.path) - _vm.$store.state.indexStep)+" ")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mt-3 w-100 text-center"},[_c('i',{staticClass:"el-icon-arrow-up mr-3"}),_vm._v(" 我們重新設計比賽用的電路版結構，並且開發易於除錯的圖紙，節省除錯時間 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"m-0"},[_vm._v("作品集 | 工科賽開發成果")])])}]

export { render, staticRenderFns }