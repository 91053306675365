import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    school: "國立臺灣科技大學 電子工程系",
    indexStep: 1,
  },
  mutations: {},
  actions: {},
  modules: {},
});
